<main id="main-container" class="main-container">
  <div class="toolbar site-width-container">
    <div class="header_logo">
      <a href="/home" style="display: inline-block;">
        <img id="topLogo" aria-label="The hardman trust logo" src="../assets/images/ht-logo.png" />
      </a>
    </div>
    <div class="spacer"></div>
  </div>

  <router-outlet></router-outlet>
  
</main>