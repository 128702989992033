<div class="site-width-container">
  <div class="card-heading">
    <a (click)="back()" href="javascript:void(0)" aria-label="back">
      < Back </a>
  </div>
</div>
<div class="grey-container">
  <div class="site-width-container">
    <div class="container">
      <div class="left-card">
        <div class="main-location-view" *ngIf="selectedLocation">
          <h3 class="left-chart-titles">Prison</h3>
          <img src="../../../../../assets/images/placeholder.png" alt="image">
          <span>{{selectedLocation}}</span>
          <div class="mt20">
            <a class="link mt20" (click)="backfromSelectPrison()" href="javascript:void(0)">Change prison</a>
          </div>
        </div>
        <div class="main-location-view" *ngIf="Location">
          <h3 class="left-chart-titles">Location</h3>
          <div class="view">
            <img src="../../../../../assets/images/placeholder.png" alt="">
            <span>{{Location}}<br *ngIf="level3 || level4 "><span *ngIf="level4">{{level4}},</span><span *ngIf="level3"> {{level3}}</span> </span>
            <div class="mt20">
              <a class="link" (click)="backFromLocation()" href="javascript:void(0)">Change My Location</a>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="selectedAreasOfHelp" class="selected-chip">
          <h3 class="left-chart-titles">Concerns</h3>
          <div class="custom-chip-container">
            <mat-chip-set class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
              <mat-chip class="example-box" cdkDrag *ngFor="let item of selectedAreasOfHelp">
                {{item}}
              </mat-chip>
            </mat-chip-set>
          </div>
        </div> --> 
        <div *ngIf="selectedChips?.length > 0" class="selected-chip">
          <div *ngFor="let mainArea of selectedChips" class="selected-chip clickable">
            <span (click)="handleClick(mainArea)" (keyup.enter)="handleClick(mainArea)">{{mainArea}}</span>
            <div *ngIf="selectedAreasOfHelp?.length !== 0">
              <span *ngIf="hasSubAreas(mainArea)" class="no-chip">Use the tabs below to refine your search</span>
              <div class="selected-chip2 clickable" *ngIf="hasSubAreas(mainArea)">
                <div class="custom-chip-container flex10">
                  <div *ngFor="let item of getSubAreas(mainArea)">
                    <span tabindex="0" [class.selected]="refinedSelected.includes(item) || editRefined.includes(item)" class="gray-chip"
                      [attr.aria-label]="(refinedSelected.includes(item) || editRefined.includes(item)) ? item + ' is selected' : item"
                      (click)="handleClick(item)" (keyup.enter)="handleClick(item)" id="secondary-chip">{{item}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedChips">
            <a class="link" (click)="backfromChangeChips()" href="javascript:void(0)">Update Concerns</a>
          </div>
        </div>
        


        <!-- <div *ngIf="selectedAreasOfHelp">
          <a class="link" (click)="backfromChangeChips()" href="javascript:void(0)">Update Concerns</a>
        </div> -->

        <div class="selected-chip">
          <div class="custom-chip-container">
          </div>
        </div>
        <div *ngIf="selectedIssue">
          <h3 class="left-chart-titles">Concerns</h3>
          <div class="backgroundColor">
            <span>'{{selectedIssue}}'</span>
          </div>

          <a class="link" (click)="deepSearch()" *ngIf="deepSearchText" href="javascript:void(0)">Change issue</a>
          <a class="link" (click)="backfromChangeIssue()" *ngIf="!deepSearchText" href="javascript:void(0)">Change
            issue</a>
        </div>
      </div>
      <div class="right-card-container">
        <div tabindex="0">
          <h2 style="color: #EF7950;" class="main-title secondary-color">{{locationFlag}}</h2>
        </div>
        <span class="fixMargin" *ngIf="emptyLocationFlag">There are no matching services in this {{displayText}}. Please
          use the panel on the left to change your search or <a (click)="deepSearch()" href="javascript:void(0)"
            aria-label="click here">click here</a>
          to do a more in depth search.</span>
        <!-- <div class="right-card" *ngIf="filterFunList">
          <mat-card tabindex="0" class="example-card" *ngFor="let data of filterFunList"
            (keydown.enter)="detailedView('service',data.id)" (click)="detailedView('service',data.id)">
            <mat-card-header>
            </mat-card-header>
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-title 
                  class="title">
                  <strong class="pl-10">{{data?.serviceName}}</strong>
                  <quill-view class="ml--10" [content]="(data?.serviceDescription | slice:0:256) + (data?.serviceDescription?.length > 256 ? '...' : '')"></quill-view>
                  <mat-card-content>
                    <div class="title-details" *ngIf="data?.areas_of_helps.transformedAreas">
                       <label>Areas of helps</label>
                      <div class="hr-chip padding">
                      <div *ngFor="let purpose of data?.areas_of_helps.transformedAreas" class="mr-10">
                          <div class="custom-chip-container" [ngClass]="{'blue-background': hasPriorityMatch(purpose)}"
                              [class.has-subpurposes]="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0">
                              <mat-chip-set class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                                  <mat-chip class="example-box main-purpose-chip sub-level"
                                      cdkDrag>{{purpose.areasOfHelf}}</mat-chip>
                              </mat-chip-set>
                          </div>
                           <div *ngIf="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0"
                              class="subpurpose-container">
                              <div class="custom-chip-container subpurpose-chip-container" cdkDropList
                                  cdkDropListOrientation="horizontal">
                                  <mat-chip *ngFor="let subPurpose of purpose.subAreasOfHelp"
                                      class="example-box subpurpose-chip" cdkDrag>
                                      {{subPurpose.areasOfHelf}}
                                  </mat-chip>
                              </div>
                          </div> 
                      </div>
                      </div>
                  </div> 
                  </mat-card-content>
                </mat-card-title>
                <div style="padding-left: 10px;">
                  <img class="image" mat-card-sm-image *ngIf="data?.serviceLogo?.formats" src="{{imagePath}}{{data?.serviceLogo?.formats?.thumbnail?.url}}" alt="image">
                  <img class="image" mat-card-sm-image *ngIf="!data?.serviceLogo?.formats"
                  src="{{imagePath}}{{data?.serviceLogo?.url}}" alt="image">
                </div>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
        </div> -->
        <div class="right-card" *ngIf="filterFunList">
          <mat-card tabindex="0" class="example-card" *ngFor="let data of filterFunList"
            (keydown.enter)="detailedView('service',data.id)" (click)="detailedView('service',data.id)">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-title 
                class="title"><strong class="pl-10">{{data?.serviceName}}</strong>
                <quill-view class="ml--10" [content]="(data?.serviceDescription | slice:0:256) + (data?.serviceDescription?.length > 256 ? '...' : '')"></quill-view>
                  <mat-card-content>
                    <div class="title-details" *ngIf="data?.areas_of_helps.transformedAreas">
                      <!-- <label>Areas of helps</label> -->
                      <div class="title-details" *ngIf="data?.areas_of_helps.transformedAreas">
                        <!-- <label>Areas of helps</label> -->
                        <div class="hr-chip padding">
                        <div *ngFor="let purpose of data?.areas_of_helps.transformedAreas" class="mr-10">
                            <div class="custom-chip-container" [ngClass]="{'blue-background': hasPriorityMatch(purpose)}"
                                [class.has-subpurposes]="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0">
                                <mat-chip-set class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                                    <mat-chip class="example-box main-purpose-chip sub-level"
                                        cdkDrag>{{purpose.areasOfHelf}}</mat-chip>
                                </mat-chip-set>
                            </div>
                            <!-- <div *ngIf="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0"
                                class="subpurpose-container">
                                <div class="custom-chip-container subpurpose-chip-container" cdkDropList
                                    cdkDropListOrientation="horizontal">
                                    <mat-chip *ngFor="let subPurpose of purpose.subAreasOfHelp"
                                        class="example-box subpurpose-chip" cdkDrag>
                                        {{subPurpose.areasOfHelf}}
                                    </mat-chip>
                                </div>
                            </div> -->
                        </div>
                        </div>
                    </div>
                  </div>
                  </mat-card-content></mat-card-title>
                <div style="padding-left: 10px;">
                  <img class="image" mat-card-sm-image *ngIf="data?.serviceLogo?.formats" src="{{imagePath}}{{data?.serviceLogo?.formats?.thumbnail?.url}}" alt="image">
                  <img class="image" mat-card-sm-image *ngIf="!data?.serviceLogo?.formats"
                  src="{{imagePath}}{{data?.serviceLogo?.url}}" alt="image">
                </div>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
        </div>
        <div class="national-services-container" [ngClass]="{'border-top': usertype !== 'Prison'}">
          <div [tabindex]="usertype !== 'Prison' ? 0 : -1">
            <h3 class="main-title" *ngIf="usertype !== 'Prison' && filterServiceList?.length !== 0">Here are some
              national services that may be of help.</h3>
          </div>
          <div class="right-card">
            <mat-card class="example-card result" tabindex="0" *ngFor="let data of filterServiceList"
              (keydown.enter)="detailedView('national',data.id)" (click)="detailedView( 'national',data.id)">
              <mat-card-header style="padding: 16px;">
                <mat-card-title-group>
                  <mat-card-title class="title pl-10"><strong>{{data.nationalService}}</strong>
                    <quill-view class="ml--25" [content]="(data?.serviceDescription | slice:0:256) + (data?.serviceDescription?.length > 256 ? '...' : '')"></quill-view>
                    <mat-card-content>
                      <div class="title-details" *ngIf="data?.areas_of_helps.transformedAreas">
                        <!-- <label>Areas of helps</label> -->
                        <div class="hr-chip">
                          <div class="title-details" *ngIf="data?.areas_of_helps.transformedAreas">
                            <!-- <label>Areas of helps</label> -->
                            <div class="hr-chip">
                            <div *ngFor="let purpose of data?.areas_of_helps.transformedAreas" class="mr-10">
                                <div class="custom-chip-container" [ngClass]="{'blue-background': hasPriorityMatch(purpose)}"
                                    [class.has-subpurposes]="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0">
                                    <mat-chip-set class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                                        <mat-chip class="example-box main-purpose-chip sub-level"
                                            cdkDrag>{{purpose.areasOfHelf}}</mat-chip>
                                    </mat-chip-set>
                                </div>
                                <!-- <div *ngIf="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0"
                                    class="subpurpose-container">
                                    <div class="custom-chip-container subpurpose-chip-container" cdkDropList
                                        cdkDropListOrientation="horizontal">
                                        <mat-chip *ngFor="let subPurpose of purpose.subAreasOfHelp"
                                            class="example-box subpurpose-chip" cdkDrag>
                                            {{subPurpose.areasOfHelf}}
                                        </mat-chip>
                                    </div>
                                </div> -->
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </mat-card-content>
                  </mat-card-title>
                  <div style="padding-left: 10px;">
                      <img class="image" mat-card-sm-image *ngIf="data?.serviceLogo?.formats" src="{{imagePath}}{{data?.serviceLogo?.formats?.thumbnail?.url}}" alt="image">
                      <img class="image" mat-card-sm-image *ngIf="!data?.serviceLogo?.formats"
                      src="{{imagePath}}{{data?.serviceLogo?.url}}" alt="image">
                  </div>
                </mat-card-title-group>
              </mat-card-header>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>