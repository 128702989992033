import { Component, AfterViewInit, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  private map;

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() address: any;
  constructor() { }

  ngAfterViewInit(): void {
    this.initMap();
  }
  
  private initMap(): void {
    const locationDetails = this.address;
    const popupContent = `
      <strong>Location Details:</strong><br>
      ${locationDetails.town}, ${locationDetails.cityOrCounty}, ${locationDetails.postcode}<br>
      <em></em>
    `;
    this.map = L.map('map', {
      center: [this.latitude, this.longitude],
      zoom: 13
    });
  
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
  
    tiles.addTo(this.map);
  
  
    const customIcon = L.icon({
      iconUrl: '/assets/icons/marker.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
  
    L.marker([this.latitude, this.longitude], { icon: customIcon }).addTo(this.map)
      .bindPopup(popupContent)
      .openPopup();
  
    if (L.GeometryUtil) {
      const destinationPoint = L.GeometryUtil.destination([this.latitude, this.longitude], 2, 90);
  
      const circle = L.circle(destinationPoint, {
        radius: 2000, 
        opacity: 0,   
      });
    
      circle.addTo(this.map);
    }
  }
}