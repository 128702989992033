import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NationalService } from '../national-services.service';

@Component({
  selector: 'app-deep-search',
  templateUrl: './deep-search.component.html',
  styleUrls: ['./deep-search.component.scss']
})
export class DeepSearchComponent implements OnInit {

  deepSerchForm!: FormGroup;
  selectedLocation: any;
  selectedIssue: any;
  level3: any;
  Prison: any;
  chipFlag: any;
  chips: any;
  both: any;
  userLocation: any;
  issue: any;
  usertype: any;
  newLon: any;
  newLat: any;
  currentLocation: any;
  postcodeFlag: any;
  selectedPrison: any;
  inputDeerSearch: any;
  delay: boolean = false;
  prisonId: any;
  level4: any;
  
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
  ) {
    this.route.queryParams.subscribe(params => {
      this.Prison = params['Prison']
      this.selectedLocation = params['selectedLocation']
      this.chipFlag = params['chip']
      this.chips = params['selectedChip']
      this.both = params['both']
      this.userLocation = params['Location']
      this.issue = params['selectedIssue']
      this.usertype = params['usertype'];
      this.newLon = params['Lon'];
      this.newLat = params['Lat'];
      this.level3 = params['level3'];
      this.level4 = params['level4'];
      this.currentLocation = params['currentFlag'];
      this.prisonId = params['prisonId']
      this.postcodeFlag = params['validPostCode'];
      if (this.Prison != "true") {
        this.selectedPrison = params['selectedLocation'];
        this.Prison = params['prisonId']
      }
    })
    setTimeout(() => {
      this.delay = true
    }, 150);
  }

  ngOnInit(): void {
    this.initDataForms();
  }

  back() {
    this.inputDeerSearch=this.issue
    this.router.navigate(['/home/national-services'], { queryParams: { selectedIssue: this.inputDeerSearch, selectedLocation: this.selectedPrison, usertype: this.usertype, Lon: this.newLon, Lat: this.newLat, currentFlag: this.currentLocation, validPostCode: this.postcodeFlag ,Location:this.userLocation,selectedChip:this.chips,prisonId:this.prisonId,level3:this.level3 , level4:this.level4} });
  }

  initDataForms() {
    this.deepSerchForm = this.fb.group({
      inputServiceSerch: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
    })
  }

  onSubmit(name: any, deepSearch: any) {
    this.inputDeerSearch = name.inputServiceSerch
    this.chips=null
    this.router.navigate(['/home/national-services'], { queryParams: { selectedIssue: name.inputServiceSerch, selectedLocation: this.selectedPrison, usertype: this.usertype, Lon: this.newLon, Lat: this.newLat, currentFlag: this.currentLocation, validPostCode: this.postcodeFlag ,Location:this.userLocation ,selectedChip:this.chips ,prisonId:this.prisonId ,deepSearch:deepSearch,level3:this.level3 , level4:this.level4} });
  }

}
