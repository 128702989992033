import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/app/environments/environment';
import { NationalService } from './national-services.service';
import { Observable, catchError, map } from 'rxjs';

function sortByDynamicPriority(a, b, dynamicPriorityOrder) {
  const areasOfHelpA = a.areas_of_helps.transformedAreas.map(item => item.areasOfHelf);
  const areasOfHelpB = b.areas_of_helps.transformedAreas.map(item => item.areasOfHelf);

  const matchesA = dynamicPriorityOrder?.filter(value => areasOfHelpA.includes(value)).length;
  const matchesB = dynamicPriorityOrder?.filter(value => areasOfHelpB.includes(value)).length;

  if (matchesA > matchesB) {
    return -1;
  } else if (matchesA < matchesB) {
    return 1;
  }
  return 0;
}

export interface SearchResult {
  areasOfHelf: any;
  id: string;
  Prison: string;
  length: number;
  subAreasOfHelp: any;
  selectedChip:any;
}

export interface SearchResult1 {
  mainSelectedChip: string;
  subAreas: string[];
}

@Component({
  selector: 'app-national-services',
  templateUrl: './national-services.component.html',
  styleUrls: ['./national-services.component.scss']
})
export class NationalServicesComponent implements OnInit {

  offset = 0;
  allNationalServiceResult = [];
  maxlimit = 0;
  allServiceCount = 0;
  aareasOfHelp = [];
  imagePath: any;
  imageURL: any;
  selectedChips: any;
  selectedLocation: any;
  selectedIssue: any;
  Location: any;
  searchServiceByText: any;
  selectedAreasOfHelp: SearchResult1[] = [];
  allServicesByPrison = [];
  allLocationList = [];
  prisonId: string[];
  chips: any;
  usertype: any;
  stringLocation: string;
  lon: any;
  lat: any;
  currentLocation: any;
  postcodeFlag: any;
  level3: any;
  level4: any;
  locationFlag: any;
  DisplyMessage: string;
  emptyLocationFlag: boolean = false;
  deepSearchText: any;
  displayText: string;
  town: string;
  cityOrCounty: string;
  selectedValue: any;
  refinedSelected = [];
  changeMainChipFlag: any;
  editRefined = [];
  fundingList: any[];
  filterFunList: any[];
  selectedData: any;
  allAreasOfHelpResult: SearchResult[] = [];
  allAreaCount: any;
  selectedMainChips: string;
  maxlimit1 = 0;
  dynamicPriorityOrder: string[][];
  serviceList: any[];
  filterServiceList: any[];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly nationalService: NationalService,

  ) {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe(params => {
      this.selectedData = params;
      this.selectedChips = params['selectedChip']
      this.selectedLocation = params['selectedLocation']
      this.Location = params['Location']
      this.selectedIssue = params['selectedIssue']
      this.searchServiceByText = params['selectedIssue']
      this.prisonId = params['prisonId']
      this.usertype = params['usertype'];
      this.lon = params['Lon'];
      this.lat = params['Lat'];
      this.level3 = params['level3'];
      this.level4 = params['level4'];
      this.currentLocation = params['currentFlag'];
      this.postcodeFlag = params['validPostCode']
      this.deepSearchText = params['deepSearch'];
      this.selectedValue = params['selectedSubChip'];
      if (this.selectedChips) {
        this.selectedChips = this.selectedChips.split(',');
      } 
      if (this.usertype == 'Community') {
        this.displayText = 'area'
      } else {
        this.displayText = 'prison'
      }
    })
  }

  async ngOnInit() {
    this.getAllAreaList().subscribe(() => {
      this.getSubAreaList(this.selectedChips);
    });
    this.imagePath = environment.imgPath;
    if (this.Location) {
      await this.searchServiceByLocation();
    } else {
      await this.getServiceResult();
    }
    if (this.selectedValue) {
      this.selectedValue = this.selectedValue.split(',');
      this.editRefined = this.selectedValue;
      this.handleClick(this.selectedValue);
    }
  }

  async handleClick(selectedValue) {
    this.selectedValue = selectedValue;
    this.fundingList = this.allLocationList;
    this.serviceList = this.allNationalServiceResult;
    if (this.selectedChips.includes(this.selectedValue)) {
      this.filterFunList = this.allLocationList;
      this.filterServiceList = this.allNationalServiceResult;
      this.refinedSelected = [];
    } else if (Array.isArray(this.selectedValue) && Array.isArray(this.refinedSelected) &&
      this.selectedValue.length === this.refinedSelected.length &&
      this.selectedValue.every((value, index) => value === this.refinedSelected[index])) {
      this.filterFunList = this.allLocationList;
      this.filterServiceList = this.allNationalServiceResult;
    }
    else if (selectedValue != this.selectedChips && this.selectedValue != null) {
      const index = this.refinedSelected.indexOf(selectedValue);
      if (index !== -1) {
        this.refinedSelected.splice(index, 1);
      } else {
        this.refinedSelected.push(selectedValue);
      }
      this.refinedSelected = this.refinedSelected.flatMap((element: string[]) => element);
      this.selectedValue = this.refinedSelected.length > 0 ? this.refinedSelected : null;
      this.editRefined = [];
      if (this.selectedValue != null) {
        this.filterFunList = this.fundingList.filter(item =>
          item?.areas_of_helps?.transformedAreas.some(Area =>
            Area.subAreasOfHelp.some(subArea =>
              this.selectedValue.includes(subArea.areasOfHelf)
            )
          )
        );
        this.filterServiceList = this.serviceList.filter(item =>
          item?.areas_of_helps?.transformedAreas.some(Area =>
            Area.subAreasOfHelp.some(subArea =>
              this.selectedValue.includes(subArea.areasOfHelf)
            )
          )
        );
      } else {
        this.filterFunList = this.fundingList;
        this.filterServiceList = this.serviceList;
      }
    } else {
      this.filterFunList = this.fundingList;
      this.filterServiceList = this.serviceList;
    }
    if (this.filterFunList?.length == 0) {
      this.DisplyMessage = "There are no services matching your search in this area";
      this.emptyLocationFlag = true;
    } else {
      this.emptyLocationFlag = false;
    }
  }

  back() {
    if (this.deepSearchText) {
      this.selectedIssue = null
    }
    this.selectedMainChips = this.selectedChips?.join(',');
    this.selectedValue = this.refinedSelected.join(',');
    this.router.navigate(['/home/services/need-support'], { queryParams: { selectedChip: this.selectedMainChips, selectedLocation: this.selectedLocation, selectedIssue: this.selectedIssue, Location: this.Location, prisonId: this.prisonId, usertype: this.usertype, both: true, Lon: this.lon, Lat: this.lat, currentFlag: this.currentLocation, level3: this.level3, level4: this.level4, validPostCode: this.postcodeFlag, selectedSubChip: this.selectedValue } });
  }

  deepSearch() {
    this.selectedMainChips = this.selectedChips?.join(',');
    this.router.navigate(['/home/national-services/Deep-search'], { queryParams: { selectedLocation: this.selectedLocation, selectedChip: this.selectedMainChips, selectedIssue: this.selectedIssue, Location: this.Location, prisonId: this.prisonId, usertype: this.usertype, Lon: this.lon, Lat: this.lat, currentFlag: this.currentLocation, validPostCode: this.postcodeFlag, level3: this.level3, level4: this.level4 } });
  }

  detailedView(serviceType: any, dataID: any) {
    this.selectedMainChips = this.selectedChips?.join(',');
    this.selectedValue = this.refinedSelected.join(',');
    this.router.navigate(['/home/national-services/view-national-services'], { queryParams: { id: dataID, selectedLocation: this.selectedLocation, selectedChip: this.selectedMainChips, selectedIssue: this.selectedIssue, Location: this.Location, prisonId: this.prisonId, usertype: this.usertype, service: serviceType, Lon: this.lon, Lat: this.lat, currentFlag: this.currentLocation, validPostCode: this.postcodeFlag, level3: this.level3, level4: this.level4, selectedSubChip: this.selectedValue } });
  }

  backfromSelectPrison() {
    if (this.deepSearchText) {
      this.selectedIssue = null
    }
    this.selectedMainChips = this.selectedChips?.join(',');
    this.selectedValue = this.refinedSelected.join(',');
    this.router.navigate(['/home/services/need-support'], { queryParams: { selectedChip: this.selectedMainChips, selectedLocation: this.selectedLocation, selectedIssue: this.selectedIssue, usertype: this.usertype, Prison: true, selectedSubChip: this.selectedValue } });
  }

  backFromLocation() {
    if (this.deepSearchText) {
      this.selectedIssue = null
    }
    this.selectedMainChips = this.selectedChips?.join(',');
    this.selectedValue = this.refinedSelected.join(',');
    this.router.navigate(['/home/services/need-support'], { queryParams: { selectedChip: this.selectedMainChips, selectedIssue: this.selectedIssue, usertype: this.usertype, Prison: true, selectedSubChip: this.selectedValue } });
  }

  backfromChangeIssue() {
    this.selectedMainChips = this.selectedChips?.join(',');
    this.router.navigate(['/home/services/need-support'], { queryParams: { selectedLocation: this.selectedLocation, selectedChip: this.selectedMainChips, Location: this.Location, prisonId: this.prisonId, usertype: this.usertype, Lon: this.lon, Lat: this.lat, currentFlag: this.currentLocation, validPostCode: this.postcodeFlag, level3: this.level3, level4: this.level4 } });
  }

  backfromChangeChips() {
    this.router.navigate(['/home/services/need-support'], { queryParams: { selectedChip: null, selectedLocation: this.selectedLocation, Location: this.Location, prisonId: this.prisonId, usertype: this.usertype, chip: true, Lon: this.lon, Lat: this.lat, currentFlag: this.currentLocation, validPostCode: this.postcodeFlag, level3: this.level3, level4: this.level4, selectedSubChip: null } });
  }

  searchNationalList(): Promise<any> {
    return new Promise((resolve, reject) => {
        let query = "";
        let limit = 300;
        let filterCondition: any = [];

        if (this.searchServiceByText) {
            query = this.searchServiceByText;
            filterCondition = [];
        }

        if (this.searchServiceByText == null) {
            if (this.selectedChips != null) {
                const areasFilter = `areas_of_helps.transformedAreas.areasOfHelf IN [${this.selectedChips.map(area => `"${area}"`).join(', ')}]`;
                filterCondition = [areasFilter];
            }
        }

        const payload = {
            q: query,
            limit: limit,
            offset: this.offset,
            filter: filterCondition,
            attributesToSearchOn: ['nationalService', 'newDescription']
        };

        this.nationalService.search(payload, 'national').subscribe((result) => {
            this.allNationalServiceResult = this.allNationalServiceResult.concat(result.hits);
            this.locationFlag = "If you are in crisis please call The Samaritans on 116 123";
            this.maxlimit = result.estimatedTotalHits;
            this.allServiceCount = this.allNationalServiceResult.length + 1;

            if (this.allServiceCount < this.maxlimit) {
                this.offset += limit;
                this.searchNationalList().then(resolve).catch(reject);
            } else {
                const dynamicPriorityOrder = this.selectedChips;
                this.allNationalServiceResult = this.allNationalServiceResult.sort((a, b) => sortByDynamicPriority(a, b, dynamicPriorityOrder));
                this.serviceList = this.allNationalServiceResult;
                this.filterServiceList = this.allNationalServiceResult;
                this.imagePath = environment.imgPath;
                resolve(this.allNationalServiceResult);
            }
        }, (error) => {
            reject(error);
        });
    });
}


  getServiceResult(): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = "";
      let filterCondition: any = [];
      if (this.searchServiceByText) {
        query = this.searchServiceByText;
        filterCondition = [`prisons.id = ${this.prisonId}`, `(communityOrCustodyService =Custody OR communityOrCustodyService =Both)`].join(' AND ');
      }
      if (this.searchServiceByText == null) {
        if (this.selectedChips != null) {
          const areasFilter = `areas_of_helps.transformedAreas.areasOfHelf IN [${this.selectedChips.map(area => `"${area}"`).join(', ')}]`;
          filterCondition = [`prisons.id = ${this.prisonId}`, areasFilter, `(communityOrCustodyService =Custody OR communityOrCustodyService =Both)`].join(' AND ');
        } else {
          filterCondition = ["prisons.id= " + this.prisonId, `(communityOrCustodyService =Custody OR communityOrCustodyService =Both)`].join(' AND ');
        }
      }
      this.offset = 0;
      let limit = 50;
      const payload = {
        q: query,
        limit: limit,
        offset: this.offset,
        filter: filterCondition,
        attributesToSearchOn: ['serviceName', 'newDescription']
      };
      this.nationalService.searchServicesByPrisons(payload).toPromise().then((result) => {
        this.allServicesByPrison = this.allServicesByPrison.concat(result.hits);
        this.locationFlag = "If you are in crisis, please speak to a Listener";
        if (this.allServicesByPrison.length == 0) {
          this.DisplyMessage = "There are no services matching your search in this prison";
          this.emptyLocationFlag = true;
        }
        this.maxlimit = result.estimatedTotalHits;
        this.allServiceCount = this.allServicesByPrison.length + 1;
        if (this.allServiceCount < this.maxlimit) {
          this.offset += limit;
          this.getServiceResult().then(resolve).catch(reject);
        } else {
          const dynamicPriorityOrder = this.selectedChips;
          this.allLocationList = this.allServicesByPrison.sort((a, b) => sortByDynamicPriority(a, b, dynamicPriorityOrder));
          this.fundingList = this.allLocationList;
          this.filterFunList = this.allLocationList;
          resolve(this.allLocationList);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  
  searchServiceByLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = "";
      let filterCondition: any = [];
      this.offset = 0;
      let limit = 50;
      this.stringLocation = JSON.stringify(this.Location);
      this.town = JSON.stringify(this.level4);
      this.cityOrCounty = JSON.stringify(this.level3);
      const radius = 16093.4; // 10 miles radius in meters.

      if (this.searchServiceByText) {
        query = this.searchServiceByText;
        filterCondition = [`_geoRadius( ${this.lat},${this.lon}, ${radius})`, `(communityOrCustodyService =Community OR communityOrCustodyService =Both)`].join(' AND ');
      }

      if (this.searchServiceByText == null) {
        if (this.selectedChips != null) {
          const areasFilter = `areas_of_helps.transformedAreas.areasOfHelf IN [${this.selectedChips.map(area => `"${area}"`).join(', ')}]`;
          filterCondition = [`_geoRadius( ${this.lat},${this.lon}, ${radius})`, areasFilter, `(communityOrCustodyService =Community OR communityOrCustodyService =Both)`].join(' AND ');
        }
      }

      if (this.searchServiceByText == null && this.selectedChips == null) {
        filterCondition = [`_geoRadius( ${this.lat},${this.lon}, ${radius})`, `(communityOrCustodyService =Community OR communityOrCustodyService =Both)`].join(' AND ');
      }

      const payload = {
        q: query,
        limit: limit,
        offset: this.offset,
        filter: filterCondition,
        attributesToSearchOn: ['serviceName', 'newDescription']
      };

      this.nationalService.searchServicesByPrisons(payload).subscribe(
        async (result) => {
          this.allLocationList = this.allLocationList.concat(result.hits);
          if (this.allLocationList.length == 0) {
            this.DisplyMessage = "There are no services matching your search in this area";
            this.emptyLocationFlag = true;
          }
          this.maxlimit1 = result.estimatedTotalHits;
          this.allServiceCount = this.allLocationList.length + 1;

          if (this.allServiceCount < this.maxlimit1) {
            this.offset += limit;
            this.searchServiceByLocation().then(resolve).catch(reject);
          }

          if (this.allLocationList.length === this.maxlimit1 || this.maxlimit1 == 0) {
            await this.searchNationalList();
          }

          const dynamicPriorityOrder = this.selectedChips;
          this.allLocationList = this.allLocationList.sort((a, b) => sortByDynamicPriority(a, b, dynamicPriorityOrder));
          this.fundingList = this.allLocationList;
          this.filterFunList = this.allLocationList;
          resolve(this.allLocationList);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }


  getAllAreaList(): Observable<any> {
    const query = "";
    let limit = 300;
    const payload = {
      q: query,
      limit: limit,
      offset: this.offset
    };
    return this.nationalService.searchAreasOfHelp(payload).pipe(
      map((result) => {
        this.allAreasOfHelpResult = this.allAreasOfHelpResult.concat(result.hits);
        this.maxlimit = result?.meta?.pagination?.total;
        this.allAreaCount = this.allAreasOfHelpResult.length + 1;

        if (this.allAreaCount < this.maxlimit) {
          this.offset += limit;
          return this.getAllAreaList();
        } else {
          const transformedData = this.transformEntry(this.allAreasOfHelpResult);
          const repeatedAreas = this.checkRepeatedareasOfHelf(transformedData.transformedAreas);
          this.allAreasOfHelpResult = repeatedAreas;
          return this.allAreasOfHelpResult;
        }
      }),
      catchError(error => {
        console.error("Error in getAllAreaList: ", error);
        throw error;
      })
    );
  }

  checkRepeatedareasOfHelf(transformedAreas: any[]) {
    const uniqueAreaSet = new Set<string>();
    const mergedAreas: any[] = [];
    for (const Area of transformedAreas) {
      const areasOfHelf = Area.areasOfHelf;
      if (uniqueAreaSet.has(areasOfHelf)) {
        const existingAreaIndex = mergedAreas.findIndex(item => item.areasOfHelf === areasOfHelf);
        if (existingAreaIndex !== -1) {
          mergedAreas[existingAreaIndex].subAreasOfHelp = mergedAreas[existingAreaIndex].subAreasOfHelp.concat(Area.subAreasOfHelp);
        }
      } else {
        uniqueAreaSet.add(areasOfHelf);
        mergedAreas.push(Area);
      }
    }
    mergedAreas.sort((a, b) => a.areasOfHelf.localeCompare(b.areasOfHelf));
    mergedAreas.forEach((Area) => {
      if (Array.isArray(Area.subAreasOfHelp)) {
        Area.subAreasOfHelp.sort((a, b) => a.areasOfHelf.localeCompare(b.areasOfHelf));
      }
    });
    return mergedAreas;
  }

  transformEntry(allAreaResult: any[]) {
    const transformedAreas = [];
    const mainAreaMap = new Map();
    for (const Area of allAreaResult) {
      if (Area.subAreasOfHelp.length > 0) {
        for (const mainArea of Area.subAreasOfHelp) {
          if (!mainAreaMap.has(mainArea.id)) {
            mainAreaMap.set(mainArea.id, {
              ...mainArea,
              subAreasOfHelp: []
            });
          }
          mainAreaMap.get(mainArea.id).subAreasOfHelp.push({
            id: Area.id,
            areasOfHelf: Area.areasOfHelf,
            createdAt: Area.createdAt,
            updatedAt: Area.updatedAt,
            publishedAt: Area.publishedAt
          });
        }
      }
      else {
        if (Area.subAreasOfHelp.length === 0) {
          transformedAreas.push({
            id: Area.id,
            areasOfHelf: Area.areasOfHelf,
            createdAt: Area.createdAt,
            updatedAt: Area.updatedAt,
            publishedAt: Area.publishedAt,
            subAreasOfHelp: []
          });
        }
      }
    }
    transformedAreas.push(...mainAreaMap.values());
    return {
      transformedAreas
    };
  }

  getSubAreaList(selectedChips: string[]) {
    this.selectedAreasOfHelp = [];

    selectedChips?.forEach(selectedChip => {
      const selectedArea = this.allAreasOfHelpResult.find(area => area.areasOfHelf === selectedChip);

      if (selectedArea && selectedArea.subAreasOfHelp) {
        // Push the subareas for each main selected chip
        const subAreaNames = selectedArea.subAreasOfHelp.map(subArea => subArea.areasOfHelf);
        this.selectedAreasOfHelp.push({ mainSelectedChip: selectedChip, subAreas: subAreaNames });
      }
    });
  }

  hasSubAreas(mainArea: string): boolean {
    const selectedArea = this.selectedAreasOfHelp.find(item => item.mainSelectedChip === mainArea);
    return selectedArea && selectedArea.subAreas.length > 0;
  }

  getSubAreas(mainArea: string): string[] {
    const selectedArea = this.selectedAreasOfHelp.find(item => item.mainSelectedChip === mainArea);
    return selectedArea ? selectedArea.subAreas : [];
  }

  hasPriorityMatch(purpose: any): boolean {
    this.dynamicPriorityOrder = this.selectedChips;
    if(this.dynamicPriorityOrder){
      const areasOfHelp = purpose.areasOfHelf;
      const matches = Object.values(this.dynamicPriorityOrder).includes(areasOfHelp);
      return matches;
    }
    return null;
  }

}
