import { environment } from "../environments/environment";

export const BaseUrl = {
    SEARCH_API_URLS : environment.apiURL,
    SEARCH_API_KEY : environment.SEARCH_API_KEY,
    REF_DATA : environment.refDataURL + '/sequation-reference-data-api',
    POSTCODE_SEARCH : environment.postCode_Search,
    REVERSE_SEARCH : environment.reverse_Search,
    FUNDING : environment.apiURL +'/api/fundings',
    PURPOSE : environment.apiURL +'/api/purposes',
    ORGANISATION : environment.hardmanURL + '/api/orgnisations',
    HARDMAN_URL: environment.hardmanURL,
    
};