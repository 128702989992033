import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NationalServicesComponent } from './national-services.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { FilterPipeModule } from 'src/app/shared/pipes/filter.module';
import { NationalServicesRoutingModule } from './national-services-routing.module';
import { ViewNationalServicesComponent } from './view-national-services/view-national-services.component';
import { QuillModule } from 'ngx-quill';
import { DeepSearchComponent } from './deep-search/deep-search.component';
import { MapComponent } from '../../../map/map.component';



@NgModule({
  declarations: [ NationalServicesComponent, ViewNationalServicesComponent , DeepSearchComponent,MapComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NationalServicesRoutingModule,
    FilterPipeModule,
    QuillModule.forRoot()
  ],
  providers: []
})
export class NationalServicesModule { }
