import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit{


  constructor(
    private readonly router: Router
  ) {}
  
  
  ngOnInit(): void {
  }


  goToDirectory() {
    window.scrollTo(0, 0);
    this.router.navigate(['/home/services'], { queryParams: { usetype:'Directory' } });
  }
}
