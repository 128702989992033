<div class="card-heading site-width-container mt20">
    <a (click)="back()" href="javascript:void(0)" aria-label="back">
        < Back </a>
</div>
<div class="grey-container">
    <div class="site-width-container">
        <div class="view-containers" *ngIf="service!='service'">
            <mat-card class="example-card" *ngFor="let item of nationalServiceResult">
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title *ngIf="item?.nationalService" class="main" style="font-family: 'Poppins';"><strong>{{item.nationalService}}</strong><br>
                            <div style="margin-bottom: 15px;" class="title-details"
                                *ngIf="item.areas_of_helps.transformedAreas">
                                <div *ngFor="let purpose of item.areas_of_helps.transformedAreas">
                                    <div class="custom-chip-container"
                                        [class.has-subpurposes]="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0">
                                        <mat-chip
                                            class="example-box main-purpose-chip sub-level">{{purpose.areasOfHelf}}</mat-chip>
                                        <ng-container
                                            *ngIf="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0"
                                            class="subpurpose-container">
                                            <mat-chip *ngFor="let subPurpose of purpose.subAreasOfHelp"
                                                class="example-box subpurpose-chip">
                                                {{subPurpose.areasOfHelf}}
                                            </mat-chip>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-card-title>

                        <div class="custom-chip-container">
                            <img class="img" mat-card-sm-image [src]="imagePath" alt="funding image">
                        </div>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>
                    <div class="view-container">
                        <div class="title-details" *ngIf="item?.serviceDescription">
                            <label>Service description</label>
                            <div class="ml--22">
                                <quill-view [content]="item?.serviceDescription"></quill-view>

                            </div>
                        </div>
                        <div class="service__information-item" *ngIf="item.serviceContactDetails.websiteUrl">
                            <div class="service__information-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.488 13.6c.064-.528.112-1.056.112-1.6s-.048-1.072-.112-1.6h2.704c.128.512.208 1.048.208 1.6s-.08 1.088-.208 1.6h-2.704zm-1.416 4.448a12.52 12.52 0 001.104-2.848h2.36a6.424 6.424 0 01-3.464 2.848zm-.2-4.448h-3.744A10.615 10.615 0 0110 12c0-.544.048-1.08.128-1.6h3.744c.072.52.128 1.056.128 1.6s-.056 1.072-.128 1.6zM12 18.368a10.85 10.85 0 01-1.528-3.168h3.056A10.85 10.85 0 0112 18.368zM8.8 8.8H6.464A6.338 6.338 0 019.92 5.952C9.44 6.84 9.08 7.8 8.8 8.8zm-2.336 6.4H8.8c.28 1 .64 1.96 1.12 2.848A6.406 6.406 0 016.464 15.2zm-.656-1.6A6.594 6.594 0 015.6 12c0-.552.08-1.088.208-1.6h2.704A13.212 13.212 0 008.4 12c0 .544.048 1.072.112 1.6H5.808zM12 5.624c.664.96 1.2 2.032 1.528 3.176h-3.056A10.895 10.895 0 0112 5.624zM17.536 8.8h-2.36c-.25-.99-.621-1.947-1.104-2.848A6.39 6.39 0 0117.536 8.8zM12 4c-4.424 0-8 3.6-8 8a8 8 0 108-8z"
                                        fill="currentcolor"></path>
                                </svg>
                            </div>
                            <a *ngIf="!(item.serviceContactDetails.websiteUrl.startsWith('http://') || item.serviceContactDetails.websiteUrl.startsWith('https://'))"
                                class="service__information-content" id="url"
                                href="http://{{item.serviceContactDetails.websiteUrl}}" target="_blank"
                                style="word-break: break-word">{{ item.serviceContactDetails.websiteUrl}}</a>
                            <a *ngIf="item.serviceContactDetails.websiteUrl.startsWith('http://') || item.serviceContactDetails.websiteUrl.startsWith('https://')"
                                class="service__information-content" id="url"
                                href="{{item.serviceContactDetails.websiteUrl}}" target="_blank"
                                style="word-break: break-word">{{ item.serviceContactDetails.websiteUrl}}</a>
                            <p *ngIf="!item.serviceContactDetails.websiteUrl">{{ 'N/A'}}</p>
                        </div>
                        <div class="service__information-item" *ngIf="item.serviceContactDetails.email">
                            <div class="service__information-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.4 6H5.6c-.88 0-1.592.72-1.592 1.6L4 17.2c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V7.6c0-.88-.72-1.6-1.6-1.6zm-.32 3.4l-5.656 3.536a.81.81 0 01-.848 0L5.92 9.4a.68.68 0 11.72-1.152L12 11.6l5.36-3.352a.68.68 0 11.72 1.152z"
                                        fill="currentcolor"></path>
                                </svg>
                            </div>
                            <a class="service__information-content" *ngIf="item.serviceContactDetails.email"
                                [href]="'mailto:' + item.serviceContactDetails.email + '?subject=Your%20Subject%20Here'">
                                {{ item.serviceContactDetails.email }}
                            </a>
                        </div>
                        <div class="service__information-item service__information-item--centered"
                            *ngIf="item.serviceContactDetails.telephoneNumber">
                            <div class="service__information-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.23 13.23c-1.582 1.582-3.416 3.096-4.142 2.371-1.037-1.037-1.677-1.941-3.965-.102-2.287 1.838-.53 3.064.475 4.068 1.16 1.16 5.485.063 9.76-4.211 4.273-4.274 5.368-8.599 4.207-9.76-1.006-1.005-2.226-2.761-4.064-.474-1.839 2.287-.936 2.927.103 3.965.722.725-.79 2.56-2.373 4.143z"
                                        fill="currentcolor"></path>
                                </svg>
                            </div>
                            <div>
                                <div class="leading-none">
                                    <small>
                                        Telephone
                                    </small>
                                </div>
                                <a class="service__information-content" aria-label="Office Telephone Number"
                                    *ngIf="item.serviceContactDetails.telephoneNumber"
                                    [href]="'tel:' + item.serviceContactDetails.telephoneNumber">
                                    {{ item.serviceContactDetails.telephoneNumber }}
                                </a>
                            </div>
                        </div>
                        <div class="title-details" *ngIf="serviceDeliveryTimesNational?.length !==0">
                            <label>Opening hours</label>
                            <table class="my-table" *ngFor="let dataTime of serviceDeliveryTimesNational">
                                <tr *ngIf="dataTime.closed == false || dataTime.closed == null">
                                    <td>{{dataTime.Days}}</td>
                                    <td><span>{{ formatTime(dataTime.startTime)}}-{{formatTime(dataTime.endTime)}}</span>
                                    </td>
                                </tr>
                                <tr *ngIf="dataTime.closed == true">
                                    <td>{{dataTime.Days}}</td>
                                    <td><span>Closed</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="border">
                        <div class="title-details mt15">
                            <p>Last Updated: <span>{{ item.createdAt | date :'dd/MM/yyyy'}}</span></p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="view-containers" *ngIf="service == 'service'">
            <mat-card class="example-card">
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title *ngIf="serviceData?.serviceName" class="main" style="font-family: 'Poppins';"><strong>{{ serviceData?.organisation?.organisation}}</strong><br>
                            <p class="main" style="margin-top: 10px; font-family: 'Poppins';margin-top: 0px;"><strong>{{serviceData?.serviceName}}</strong></p>
                            <div class="title-details" *ngIf="serviceData.areas_of_helps.transformedAreas">
                                <div class="custom-chip-container"
                                    *ngFor="let purpose of serviceData.areas_of_helps.transformedAreas"
                                    [class.has-subpurposes]="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0">
                                    <mat-chip
                                        class="example-box main-purpose-chip sub-level">{{purpose.areasOfHelf}}</mat-chip>
                                    <ng-container *ngIf="purpose.subAreasOfHelp && purpose.subAreasOfHelp.length > 0">
                                        <mat-chip *ngFor="let subPurpose of purpose.subAreasOfHelp"
                                            class="example-box subpurpose-chip">
                                            {{subPurpose.areasOfHelf}}
                                        </mat-chip>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-card-title>
                        <div class="custom-chip-container">
                            <img class="img" mat-card-sm-image [src]="imagePath" alt="funding image">
                        </div>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>
                    <div class="view-container">
                        <!-- <div class="title-details" *ngIf="serviceData?.serviceId">
                            <label>Service Id</label>
                            <p>{{ serviceData?.serviceId || 'N/A'}}</p>
                        </div> -->
                        <!-- <div class="title-details" *ngIf="serviceData?.serviceName">
                            <label>Service name</label>
                            <p>{{serviceData?.serviceName}}</p>
                        </div> -->
                        <div class="title-details" *ngIf="serviceData?.serviceDescription">
                            <label>Service description</label>
                            <div class="ml--22">
                                <quill-view [content]="serviceDesRichTextContent"></quill-view>

                            </div>
                        </div>
                        <!-- <div class="title-details">
                            <label>Is this a community or custody service?</label>
                            <p>{{ serviceData?.communityOrCustodyService }}</p>
                        </div> -->

                        <div
                            *ngIf="serviceData?.communityOrCustodyService=='Community' || serviceData?.communityOrCustodyService=='Both'">
                            <div class="service__information-item"
                                *ngIf="serviceData.serviceContactDetails?.websiteUrl">
                                <div class="service__information-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.488 13.6c.064-.528.112-1.056.112-1.6s-.048-1.072-.112-1.6h2.704c.128.512.208 1.048.208 1.6s-.08 1.088-.208 1.6h-2.704zm-1.416 4.448a12.52 12.52 0 001.104-2.848h2.36a6.424 6.424 0 01-3.464 2.848zm-.2-4.448h-3.744A10.615 10.615 0 0110 12c0-.544.048-1.08.128-1.6h3.744c.072.52.128 1.056.128 1.6s-.056 1.072-.128 1.6zM12 18.368a10.85 10.85 0 01-1.528-3.168h3.056A10.85 10.85 0 0112 18.368zM8.8 8.8H6.464A6.338 6.338 0 019.92 5.952C9.44 6.84 9.08 7.8 8.8 8.8zm-2.336 6.4H8.8c.28 1 .64 1.96 1.12 2.848A6.406 6.406 0 016.464 15.2zm-.656-1.6A6.594 6.594 0 015.6 12c0-.552.08-1.088.208-1.6h2.704A13.212 13.212 0 008.4 12c0 .544.048 1.072.112 1.6H5.808zM12 5.624c.664.96 1.2 2.032 1.528 3.176h-3.056A10.895 10.895 0 0112 5.624zM17.536 8.8h-2.36c-.25-.99-.621-1.947-1.104-2.848A6.39 6.39 0 0117.536 8.8zM12 4c-4.424 0-8 3.6-8 8a8 8 0 108-8z"
                                            fill="currentcolor"></path>
                                    </svg>
                                </div>
                                <a *ngIf="!(serviceData.serviceContactDetails.websiteUrl.startsWith('http://') || serviceData.serviceContactDetails.websiteUrl.startsWith('https://'))"
                                    class="service__information-content" id="url"
                                    href="http://{{serviceData.serviceContactDetails.websiteUrl}}" target="_blank"
                                    style="word-break: break-word">{{ serviceData.serviceContactDetails.websiteUrl}}</a>
                                <a *ngIf="serviceData.serviceContactDetails.websiteUrl.startsWith('http://') || serviceData.serviceContactDetails.websiteUrl.startsWith('https://')"
                                    class="service__information-content" id="url"
                                    href="{{serviceData.serviceContactDetails.websiteUrl}}" target="_blank"
                                    style="word-break: break-word">{{ serviceData.serviceContactDetails.websiteUrl}}</a>
                                <p *ngIf="!serviceData.serviceContactDetails.websiteUrl">{{ 'N/A'}}</p>
                            </div>
                            <div class="service__information-item" *ngIf="serviceData.serviceContactDetails?.email">
                                <div class="service__information-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.4 6H5.6c-.88 0-1.592.72-1.592 1.6L4 17.2c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V7.6c0-.88-.72-1.6-1.6-1.6zm-.32 3.4l-5.656 3.536a.81.81 0 01-.848 0L5.92 9.4a.68.68 0 11.72-1.152L12 11.6l5.36-3.352a.68.68 0 11.72 1.152z"
                                            fill="currentcolor"></path>
                                    </svg>
                                </div>
                                <a class="service__information-content" *ngIf="serviceData.serviceContactDetails.email"
                                    [href]="'mailto:' + serviceData.serviceContactDetails.email + '?subject=Your%20Subject%20Here'">
                                    {{ serviceData.serviceContactDetails.email }}
                                </a>
                            </div>
                            <div class="service__information-item service__information-item--centered"
                                *ngIf="serviceData.serviceContactDetails?.telephoneNumber">
                                <div class="service__information-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.23 13.23c-1.582 1.582-3.416 3.096-4.142 2.371-1.037-1.037-1.677-1.941-3.965-.102-2.287 1.838-.53 3.064.475 4.068 1.16 1.16 5.485.063 9.76-4.211 4.273-4.274 5.368-8.599 4.207-9.76-1.006-1.005-2.226-2.761-4.064-.474-1.839 2.287-.936 2.927.103 3.965.722.725-.79 2.56-2.373 4.143z"
                                            fill="currentcolor"></path>
                                    </svg>
                                </div>
                                <div>
                                    <div class="leading-none">
                                        <small>
                                            Telephone
                                        </small>
                                    </div>
                                    <a class="service__information-content" aria-label="Office Telephone Number"
                                        *ngIf="serviceData.serviceContactDetails.telephoneNumber"
                                        [href]="'tel:' + serviceData.serviceContactDetails.telephoneNumber">
                                        {{ serviceData.serviceContactDetails.telephoneNumber }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="serviceData?.communityOrCustodyService=='Community' || serviceData?.communityOrCustodyService=='Both'">
                            <div class="title-details"
                                *ngIf="serviceData?.adderess?.addressLine1 || serviceData?.adderess?.addressLine2 || serviceData?.adderess?.town || serviceData?.adderess?.cityOrCounty || serviceData?.adderess?.postcode">
                                <label>Address</label>
                                <p>{{serviceData?.adderess?.addressLine1}}</p>
                                <p>{{serviceData?.adderess?.addressLine2}}</p>
                                <p>{{serviceData?.adderess?.town}}</p>
                                <p>{{serviceData?.adderess?.cityOrCounty}}</p>
                                <p>{{ serviceData?.adderess?.postcode === 'N/A' ? '' : serviceData?.adderess?.postcode }}</p>
                            </div>
                            <div class="map-container"
                                *ngIf="serviceData?.adderess?.latitude && serviceData?.adderess?.longitude">
                                <app-map [latitude]="serviceData?.adderess?.latitude"
                                    [longitude]="serviceData?.adderess?.longitude"
                                    [address]="serviceData?.adderess"></app-map>

                            </div>
                        </div>

                        
                        <div
                            *ngIf="serviceData?.communityOrCustodyService=='Community' || serviceData?.communityOrCustodyService=='Both'">
                            <div class="title-details" *ngIf="serviceDeliveryTimes?.length !=0">
                                <label>Opening hours</label>
                                <table class="my-table" *ngFor="let dataTime of serviceDeliveryTimes">
                                    <tr *ngIf="dataTime.closed == false || dataTime.closed == null">
                                        <td>{{dataTime.Days}}</td>
                                        <td><span>{{ formatTime(dataTime.startTime)
                                                }}-{{formatTime(dataTime.endTime)}}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="dataTime.closed == true">
                                        <td>{{dataTime.Days}}</td>
                                        <td><span>Closed</span></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div
                            *ngIf="serviceData?.communityOrCustodyService=='Community' || serviceData?.communityOrCustodyService=='Both'">
                            <div class="title-details" *ngIf="provisionsList && provisionsList.length!= 0">
                                <label>Disability provisions</label>
                                <div *ngFor="let item of provisionsList">
                                    <p>{{item.provision}}</p>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="serviceData?.communityOrCustodyService=='Custody' || serviceData?.communityOrCustodyService=='Both'">
                            <!-- <label *ngIf="serviceData?.communityOrCustodyService == 'Both'" class="details-label">Prison information</label><br> -->
                            <div class="title-details" *ngIf="serviceData?.access?.howToAccessTheService">
                                <label class="details-label">How to access the service</label>
                                <div class="ml--22">
                                    <quill-view [content]="howToRichTextContent"></quill-view>

                                </div>
                            </div>
                            <!-- <div class="title-details" *ngIf="prisons.length!=0">
                                <label class="details-label">Available in the following prisons</label>
                                <mat-chip-set class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                                    <mat-chip class="example-box sub-level" cdkDrag *ngFor="let item of prisons">
                                        {{item?.Prison}}
                                    </mat-chip>
                                </mat-chip-set>
                            </div> -->
                            
                           
                      
                        </div>
                        
                        <div class="et_pb_row et_pb_row_4" *ngIf="serviceData?.quote?.userOrStaffQuote">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                <div
                                    class="et_pb_module et_pb_testimonial2 et_pb_testimonial_0 clearfix  et_pb_text_align_left et_pb_bg_layout_light et_pb_testimonial_no_image">
                                </div>
                                <div
                                    class="et_pb_module et_pb_testimonial et_pb_testimonial_0 clearfix  et_pb_text_align_left et_pb_bg_layout_light et_pb_testimonial_no_image">
                                    <div class="et_pb_testimonial_description" style="margin-left: 0px;">
                                        <div class="et_pb_testimonial_description_inner">
                                            <div class="et_pb_testimonial_content" id="quote">
                                                <quill-view [content]="richTextContent"></quill-view>
                                            </div>
                                        </div>
                                        <span class="et_pb_testimonial_author">{{ serviceData?.quote?.name }}</span>
                                        <p class="et_pb_testimonial_meta"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border">
                        <div class="title-details mt15">
                            <p>Last Updated: <span>{{ serviceData?.createdAt | date :'dd/MM/yyyy'}}</span></p>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>