<div class="site-width-container">
  <div class="card-heading">
    <a (click)="back()" href="javascript:void(0)" aria-label="back">
      < Back </a>
  </div>
</div>
<div [ngClass]="{'isData': !delay }" class="grey-container">
  <div class="site-width-container">
    <div class="card-container">
      <mat-card>
        <div class="flex main">
          <h2 class="ht-heading_small">Did you not find what you were looking for? Please type your issue here</h2>
          <p>Please enter what you need support with in the box</p>
          <form [formGroup]="deepSerchForm" (ngSubmit)="onSubmit(deepSerchForm.getRawValue(),'deepSearch')">
            <div class="flex">
              <div class="card-search">
                <mat-form-field appearance="outline">
                  <input #deepInput placeholder="Search for issues" autocomplete="off" matInput formControlName="inputServiceSerch"
                    id="selected_issues" minlength="1" maxlength="100" aria-label="Search for issues">
                  <mat-error *ngIf="deepSerchForm.get('inputServiceSerch')?.hasError('required')">
                    Issue is required
                  </mat-error>

                </mat-form-field>
              </div>
              <div class="card-button">
                <button type="submit" class="search-button"
                  mat-flat-button>Search</button>
              </div>
            </div>
          </form>
        </div>
      </mat-card>
    </div>
  </div>
</div>