import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isClicked: boolean = false;

  navigateToGoogle() {
    this.isClicked = true;
    window.location.href = 'https://www.google.com';
  }

}
