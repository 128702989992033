import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/app/environments/environment';
import { NationalService } from '../national-services.service';

export interface SearchResult {
  areas_of_helps: any[];
  createdAt: any
  nationalServiceId: any;
  nationalService: any;
  publishedAt: any;
  serviceContactDetails: any;
  serviceDeliveryTimes: any;
  serviceDescription: any;
  serviceLogo: any;
  updatedAt: any;

}
@Component({
  selector: 'app-view-national-services',
  templateUrl: './view-national-services.component.html',
  styleUrls: ['./view-national-services.component.scss']
})
export class ViewNationalServicesComponent implements OnInit {

  nationalServiceResult: any;
  nationalServiceID: any;
  imagePath: any;
  imageURL: any;
  startTime: string;
  selectedLocation: any;
  service: any;
  serviceDesRichTextContent: any;
  howToRichTextContent: any;
  richTextContent: any;
  serviceData: any;
  areaOfHelp = [];
  serviceDeliveryTimes = [];
  provisionsList = [];
  prisons = [];
  selectedIssue : any;
  level3 : any;
  level4 : any;
  selectedChips: any;
  selectedValue: any;
  latitude : number;
  longitude : number;
  address: any
  serviceDeliveryTimesNational: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly nationalService: NationalService,
  ) {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe(params => {
      this.nationalServiceID = params['id'];
      this.service = params['service']
      this.selectedLocation = params['selectedLocation']
      this.selectedIssue=params['selectedIssue']
      this.level3=params['level3'];
      this.level4=params['level4'];
      this.selectedChips = params['selectedChip'];
      this.selectedValue = params['selectedSubChip'];
    })
  }

  ngOnInit(): void { 
    if (this.service == 'service') {
      this.searchServiceById()
    } else {
      this.searchNationalById();
    }
  }

  back() {
    this.router.navigate(['/home/national-services'], { queryParamsHandling: 'merge', queryParams: { selectedLocation: this.selectedLocation ,selectedIssue:this.selectedIssue ,level3:this.level3 , level4:this.level4,selectedChip: this.selectedChips,selectedSubChip: this.selectedValue} });
  }

  searchNationalById() {
    const nationalId = this.nationalServiceID;
    const payload = {
      filter: [`id=${nationalId}`]
    };
    this.nationalService.search(payload, 'national').subscribe((result) => {
      this.nationalServiceResult = result.hits;
      this.serviceDeliveryTimesNational = this.nationalServiceResult[0]?.serviceDeliveryTimes;
      if(this.nationalServiceResult[0]?.serviceLogo?.formats?.thumbnail){
        this.imageURL = this.nationalServiceResult[0]?.serviceLogo?.formats?.thumbnail?.url;
      }else{
        this.imageURL = this.nationalServiceResult[0]?.serviceLogo?.url;
      }
      this.imagePath = environment.imgPath + this.imageURL;
    });
  }

  searchServiceById() {
    const nationalId = this.nationalServiceID;
    const payload = {
      filter: [`id=${nationalId}`]
    };
    this.nationalService.search(payload, 'service').subscribe((result) => {
      this.nationalServiceResult = result.hits;
      this.serviceData = this.nationalServiceResult[0]
      this.serviceDesRichTextContent = this.serviceData?.serviceDescription
      this.howToRichTextContent = this.serviceData?.access?.howToAccessTheService
      this.richTextContent = this.serviceData?.quote?.userOrStaffQuote
      this.areaOfHelp = this.serviceData?.areas_of_helps;
      this.serviceDeliveryTimes = this.serviceData?.serviceDeliveryTime;
      this.provisionsList = this.serviceData?.provisions
      this.prisons = this.serviceData?.prisons;
      if(this.nationalServiceResult[0]?.serviceLogo?.formats?.thumbnail){
        this.imageURL = this.nationalServiceResult[0]?.serviceLogo?.formats?.thumbnail?.url;
      }else{
        this.imageURL = this.nationalServiceResult[0]?.serviceLogo?.url;
      }
      
      this.imagePath = environment.imgPath + this.imageURL;
    });
  }


  formatTime(time: string): string {
    if (!time) {
      return '';
    }
    const parts = time.split(':');
    if (parts.length === 3) {
      const [hours, minutes] = parts;
      return `${hours}:${minutes}`;
    } else {
      return 'Invalid Time';
    }
  }

}
