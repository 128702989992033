<div class="site-width-container">
  <div class="card-heading">
    <div class="hmt-row">
      <div class="hmt-col-half">
        <h1 class="SSBold_Heading">Directory of support services</h1>
        <p>Starting over 15 years ago The Hardman Directory has become a go-to resource for prisoners and
          prison-leavers alike. Clicking through you will find information, resources and funding to help you move
          into work or training.</p>
        <p>Updated regularly, this new digital edition can be easily searched to find the support you need to reach
          your personal goals.</p>
      </div>
      <div class="hmt-col-half mobile-hidden">
        <div class="default-image"></div>
      </div>
    </div>
  </div>
  <div class="content">
    <h2 class="heading SSBold_Heading_small">What are you looking for?</h2>
    <p class="heading_two" style="margin-top: -10px;">Please select an option</p>
    <div class="main-card">
      <div class="card-container third">
        <div class="card" rel="noopener" href="javascript:void(0)">
          <h3 class="heading_small">Search for services</h3>
          <div class="browse_button">
            <a href="javascript:void(0)" class="ht-button" [routerLink]="['services']"
              aria-label="Search for services find">Find</a>
          </div>
        </div>
        <div class="left-slanting-line">
          <img src="../../../../assets/images/slanting-lines.png" alt="slanting-lines">
        </div>
      </div>
      <div class="card-container first">
        <div class="card" rel="noopener" href="javascript:void(0)">
          <h3 class="heading_small">Search for funding</h3>
          <div class="browse_button">
            <a href="javascript:void(0)" class="ht-button" [routerLink]="['funding']"
              aria-label="Search for funding find">Find</a>
          </div>
        </div>
        <div class="left-slanting-line">
          <img src="../../../../assets/images/slanting-lines.png" alt="slanting-lines">
        </div>
      </div>
    </div>
      <div class="card-container">
        <div class="card" rel="noopener" href="javascript:void(0)">
          <h3 class="heading_small">How to guides</h3>
          <div class="browse_button">
            <a href="javascript:void(0)" class="ht-button" [routerLink]="['how-to']"
              aria-label="How to guides find">Find</a>
          </div>
        </div>
        <div class="left-slanting-line">
          <img src="../../../../assets/images/slanting-lines.png" alt="slanting-lines">
        </div>
      </div>
    
  </div>
</div>
<div class="grey-container">
  <div class="site-width-container">
    <mat-card>
      <div class="p-15">
        <h1 class="heading SSBold_Heading_small border-bottom">Feature in the directory</h1>
        <p class="provide-text">Do you provide support,service or funding for people in the criminal justice system ?
        </p>
        <div class="card-container">
          <p>We currently feature around 400 partners and agencies across the UK. Get in touch with us if you'd like to
            be
            included in the Directory.</p>

          <div class="button-container">
            <button tabindex="0" type="button" class="search-button" [disabled]="" (click)="goToDirectory()"
              mat-raised-button>Submit a service or funding</button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>