import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeepSearchComponent } from './deep-search/deep-search.component';
import { NationalServicesComponent } from './national-services.component';
import { ViewNationalServicesComponent } from './view-national-services/view-national-services.component';


const routes: Routes = [
  {
    path: '',
    component: NationalServicesComponent,
    data: { title: 'national-services'},
    // canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'view-national-services',
    component: ViewNationalServicesComponent,
    data: { title: 'view-national-services'},
    // canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'Deep-search',
    component: DeepSearchComponent,
    data: { title: 'Deep-search'},
    // canDeactivate: [CanDeactivateGuard]
  },
  
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NationalServicesRoutingModule { }