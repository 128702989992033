import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';
import { StripTagsPipe } from './stripTags.pipe';
import { RoundPipe } from './round.pipe';
import { ReplacePipe } from './replace.pipe';
@NgModule({
  declarations: [FilterPipe, SafePipe, TruncatePipe, StripTagsPipe, RoundPipe, ReplacePipe ],
  exports: [ FilterPipe, SafePipe, TruncatePipe, StripTagsPipe, RoundPipe, ReplacePipe ]
})
export class FilterPipeModule { }
