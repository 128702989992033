import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { ResourcesComponent } from './resources/resources.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: ResourcesComponent,
        data: { title: 'Asset Builder', auth: [6] }
      },
      {
        path: 'home/funding',
        loadChildren: () => import('../home/funding/funding.module').then(m => m.FundingModule),
        data: { title: 'Services', breadcrumb: '', preload: false }
      },
      {
        path: 'home/how-to',
        loadChildren: () => import('../home/how-to/how-to.module').then(m => m.HowToModule),
        data: { title: 'Services', breadcrumb: '', preload: false }
      },
      {
        path: 'home/national-services',
        loadChildren: () => import('../home/national-services/national-services.module').then(m => m.NationalServicesModule),
        data: { title: 'national-services', breadcrumb: '', preload: false }
      },
      {
        path: 'home/services',
        loadChildren: () => import('../home/services/services.module').then(m => m.ServicesModule),
        data: { title: 'Services', breadcrumb: '', preload: false }
      },
    ]
  },
  
  {
    path: '**',
    redirectTo: 'home',
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
