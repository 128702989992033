import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseUrl } from "src/app/constants/url-constants";

@Injectable({
  providedIn: 'root'
})

export class NationalService {
  constructor(
    private readonly http: HttpClient,
  ) { }

  search(payload: any, apiName: any) {
    const searchEndpoint = `${BaseUrl.SEARCH_API_URLS}/indexes/${apiName}/search`;
    return this.http.post<any>(searchEndpoint, payload);
  }

  searchServicesByPrisons(payload: any) {
    const searchEndpoint = `${BaseUrl.SEARCH_API_URLS}/indexes/service/search`;
    return this.http.post<any>(searchEndpoint, payload);
  }

  searchAreasOfHelp(payload: any) {
    const searchEndpoint = `${BaseUrl.SEARCH_API_URLS}/indexes/areas-of-help/search`;
    return this.http.post<any>(searchEndpoint, payload);
  }


}