import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  pageTitle: string = 'Default Title';
  constructor(
    private titleService: Title
  ) {
    var isFirstLoad = !localStorage.getItem('pageLoaded');
    if (isFirstLoad) {
      this.setFocusOnTopLogo();
      localStorage.setItem('pageLoaded', 'true');
    }
  }

  ngOnInit(): void {
    var topLogo = document.getElementById('topLogo');
    topLogo.focus();
    topLogo.addEventListener('click', function () {
      localStorage.removeItem('pageLoaded');
    });

  }
  setFocusOnTopLogo() {
    var topLogo = document.getElementById('topLogo');
    if (topLogo) {
      topLogo.focus();
    }
  }

  updateTitle(newTitle: string) {
    this.pageTitle = newTitle;
    this.titleService.setTitle(newTitle);
  }

}
